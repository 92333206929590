.left-label-spacing {
	margin-right: 10px;
}

.right-result-spacing {
	margin-left: 10px;
}

.showMyDamnBorder {
	border: 2px;
	border-color: red;
	border-style: solid;
}