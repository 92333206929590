/*.header {*/
/*  max-width: 60rem;*/
/*  margin: auto;*/
/*  padding: 2rem;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/

/*.list {*/
/*  display: flex;*/
/*  gap: 1rem;*/
/*}*/

/*.list a {*/
/*  text-decoration: none;*/
/*  color: var(--color-primary-400);*/
/*}*/

/*.list a:hover,*/
/*.list a.active {*/
/*  color: var(--color-primary-800);*/
/*}*/

div {
	border: 1px;
}