.newsletter input,
.newsletter button {
  font: inherit;
  padding: 0.25rem 0.75rem;
  border-radius: 0;
  border: none;
}

.newsletter button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.newsletter button:hover {
  background-color: var(--color-primary-300);
  color: var(--color-gray-800);
}
